<template>
<Todo :widget="widget" :data="data" :editable="true" />
</template>

<script>
// این ویو برای ماژول وظایف روزانه مورد استفاده قرار میگیرد
import Todo from '@/components/Todo.vue'

export default {
    name: 'TodoView',
    components: {
        Todo,
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
    mounted() {
        // در متد مانت یک فانکشی رو از هلپر کال میکنیم جهت لود کردن داده های ویجت 
        this.$helpers.getWidgets({ widget_type: 'todo', store: false }).then((widgets) => {
            let widget = widgets[0];

            this.widget = widget.widget;
            this.data = widget.data;
        });
    },
}
</script>
